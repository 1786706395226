<template>
	<div>
		<div class="pd20" style="height: calc(100vh - 60px);">
			<div class="pd20 bg-w" style="height: 100%;">
				<div class="flex center ft18 cl-main">挂单列表</div>
				<div class="mt20">
					<a-table :columns="columns" :data-source="getDatas" :scroll="{y: 700}">

						<div slot="cart" slot-scope="cart,record">
							<div v-if="cart.length>0">
								<div v-for="(item,index) in cart" :class="{mt5:index>0}">
									商品：{{item.name}}{{item.sku_id>0 ? '('+item.sku_name+')' : ''}}
								</div>
							</div>
							<div v-else>暂无商品</div>
						</div>
						<div slot="member" slot-scope="member,record">
							{{JSON.stringify(member)!='{}' ? member.nick_name+"("+member.mobile+")" : '-'}}
						</div>

						<div slot="price" slot-scope="price,record">
							￥{{price}}
						</div>

						<div class="flex center" slot="action" slot-scope="record">
							<div>
								<a-space >
									<a-button type="primary"  @click="sureAct(record)">确定</a-button>
									<a-button @click="jiesuanAct(record)">结算</a-button>
									<a-button type="danger" @click="delAct(record)">删除</a-button>
								</a-space>
							</div>
						</div>
					</a-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				columns: [{
						title: '商品信息',
						dataIndex: 'cart',
						align: 'center',
						width: 300,
						scopedSlots: {
							customRender: 'cart'
						}
					},
					{
						title: '商品数量',
						dataIndex: 'total_num',
						align: 'center',
						ellipsis: true
					},
					{
						title: '会员名称',
						dataIndex: 'member',
						align: 'center',
						width: 200,
						scopedSlots: {
							customRender: 'member'
						}
					},
					{
						title: '支付/元',
						dataIndex: 'need_pay',
						align: 'center',
						ellipsis: true
					},
					{
						title: '创建时间',
						dataIndex: 'add_time_format',
						align: 'center',
						ellipsis: true
					},
					{
						title: '操作',
						key: 'action',
						align: 'center',
						width: 200,
						scopedSlots: {
							customRender: 'action'
						}
					}
				],
				datas: [],
			}
		},
		computed: {
			getDatas() {
				let arr = new Array;
				for (var i in this.gua) {
					arr[i] = this.gua[i];
					arr[i].key = i;
				}
				return arr;
			},
			getTotalPrice(){
				let total_price=0;
				if(this.cashierCart.length>0){
					let price=0;
					for( var i in this.cashierCart){
						if(this.cashierCart[i].type=='goods'){
							if(this.cashierCart[i].sku_id>0){
								price=this.cashierCart[i].sku_original_price;
							}else{
								price=this.cashierCart[i].original_price;
							}
						}else{
							price=this.cashierCart[i].market_price
						}
						total_price += (parseFloat(price)*100 * this.cashierCart[i].num)/100
					}
				}
				
				return total_price.toFixed(2);
				
			},
			getTotalNum(){
				let num=0;
				if(this.cashierCart.length>0){
					for( var i in this.cashierCart){
						num+=this.cashierCart[i].num;
					}
				}
				
				return num;
			},
			getNeedPay(){
				let need_pay=0;
				if(this.cashierCart.length>0){
					let price=0;
					for( var i in this.cashierCart){
						if(this.cashierCart[i].type=='goods'){
							if(this.cashierCart[i].sku_id>0){
								price=this.cashierCart[i].sku_original_price;
							}else{
								price=this.cashierCart[i].original_price;
							}
							if(JSON.stringify(this.cashierMember)!='{}'){
								if(this.cashierMember.level_id>0){
									if(this.cashierCart[i].sku_id>0){
										price=this.cashierCart[i].sku_vip_price;
									}else{
										price=this.cashierCart[i].vip_price;
									}
								}
							}
						}else{
							price=this.cashierCart[i].market_price;
						}
						need_pay += (parseFloat(price)*100 * this.cashierCart[i].num)/100
					}
				}
				
				return need_pay.toFixed(2);
			},
		},
		methods:{
			delAct(record){
				let index= record.key;
				let gua = JSON.parse(JSON.stringify(this.gua));
				gua.splice(index,1);
				this.$store.commit('setGua',gua);
			},
			jiesuanAct(record){
				let index= record.key;
				let gua = JSON.parse(JSON.stringify(this.gua));
				let item=gua[index];
				gua.splice(index,1);
				if(this.cashierCart.length>0){
					gua.unshift({
						member:this.cashierMember,
						cart:this.cashierCart,
						total_price:this.getTotalPrice,
						total_num:this.getTotalNum,
						need_pay:this.getNeedPay,
						add_time_format:this.getTime(),
					})
				}
				this.$store.commit('setGua',gua);
				let cart=item.cart;
				let member =item.member;
				this.$store.commit('setCashierCart',cart);
				this.$store.commit('setCashierCartIndex',-1);
				this.$store.commit('setMember',member);
				this.$router.push('/cash/pay');
			},
			
			sureAct(record){
				let index= record.key;
				let gua = JSON.parse(JSON.stringify(this.gua));
				let item=gua[index];
				gua.splice(index,1);
				if(this.cashierCart.length>0){
					gua.unshift({
						member:this.cashierMember,
						cart:this.cashierCart,
						total_price:this.getTotalPrice,
						total_num:this.getTotalNum,
						need_pay:this.getNeedPay,
						add_time_format:this.getTime(),
					})
				}
				this.$store.commit('setGua',gua);
				let cart=item.cart;
				let member =item.member;
				this.$store.commit('setCashierCart',cart);
				this.$store.commit('setCashierCartIndex',-1);
				this.$store.commit('setMember',member);
				this.$router.push('/index');
			},
			getTime(){
				const date = new Date();
				const year = date.getFullYear();
				const month = date.getMonth() + 1;
				const day = date.getDate();
				const hour = date.getHours();
				const minute = date.getMinutes();
				const second = date.getSeconds();
				this.month = check(month);
				this.day = check(day);
				this.hour = check(hour);
				this.minute = check(minute);
				this.second = check(second);
							
				function check(i) {
					const num = (i < 10) ? ("0" + i) : i;
					return num;
				}
							
				return year + "-" + this.month + "-" + this.day + " " + this.hour + ":" + this.minute + ":" + this.second;
			}
		}
	}
</script>

<style>
</style>
